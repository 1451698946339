import React from 'react';
import { IdsButton, IdsLink, IdsText } from '@emergn-infinity/ids-react';

import { usePathname } from 'next/navigation';

import { AppRoutes, PRAXIS_MARKETING_WEBSITE } from '@/constants/general';
import { PraxisLogo } from '../../../public/images';

import './logo-header.scss';

const LogoHeader = () => {
  const page = usePathname();

  let label: string = '';
  let ctaLabel: string = '';
  let ctaRoute = AppRoutes.SignIn;

  switch (page) {
    case AppRoutes.SignIn: {
      label = 'Not a member?';
      ctaLabel = 'Register here';
      ctaRoute = AppRoutes.SignUp;
      break;
    }
    case AppRoutes.SignUp: {
      label = 'Already a member?';
      ctaLabel = 'Sign in';
      break;
    }
    case AppRoutes.ForgotPassword:
    case AppRoutes.PasswordSent: {
      ctaLabel = 'Back to sign in';
      break;
    }
  }

  return (
    <div className="LogoHeader">
      <IdsLink href={PRAXIS_MARKETING_WEBSITE} target="_blank" customClasses="LogoHeaderLogo">
        <PraxisLogo />
      </IdsLink>
      {ctaLabel && (
        <div className="LogoHeaderCta">
          {label && <IdsText size="sm">{label}</IdsText>}
          <IdsButton variant="secondary" color="neutral" href={`/app${ctaRoute}`} data-elp-testid={ctaLabel}>
            <>{ctaLabel}</>
          </IdsButton>
        </div>
      )}
    </div>
  );
};

export default LogoHeader;
